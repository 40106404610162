<template>
  <div>
    <CAlert color="danger" closeButton v-if="error !== null">
      {{ error }}
    </CAlert>
    <CRow>
      <CCol lg="10">
        <CRow>
          <CCol sm="6">
            <CForm v-on:submit.prevent="getProjectsData">
              <CInput
                label="Project Public ID:"
                horizontal
                v-model="projectPublicId"
              />
            </CForm>
          </CCol>
          <CCol sm="3">
            <CSelect
              label="Target"
              horizontal
              :options="[{value: '', label: 'All'}, {value: 'Exaloan', label: 'Exaloan'}, {value: 'Tribe', label: 'Tribe'}, {value: 'i2invest', label: 'i2invest'}]"
              
              :value.sync="target"
            />
          </CCol>
          <CCol sm="3">
            <CSelect
              label="API Ready?"
              horizontal
              :options="[{value: -1, label: 'All'}, {value: 1, label: 'Yes'}, {value: 0, label: 'No'}]"
              
              :value.sync="apiReady"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol lg="2">
        <CButton type="submit" size="sm" color="primary" v-on:click="getProjectsData">Search</CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Application Project List
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              striped="striped"
              :items="tableItems"
              :fields="tableFields"
              :loading="isLoading"
            >
              <template #amount_loan="{item}">
                <td>
                  {{formatAmount(item.amount_loan)}}
                </td>
              </template>
              <template #action="{item}">
                <td>
                  <router-link :to="{ name: 'Edit Application Project', params: { id: item.id } }"><font-awesome-icon :icon="['fas', 'edit']" /></router-link>
                </td>
              </template>
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.api_ready)"><span v-html="getStatusSymbol(item.api_ready)"></span></CBadge>
                </td>
              </template>
            </CDataTable>
            <CPagination
                v-if="lastPage > 1"
                align="end"
                :activePage.sync="currentPage"
                :pages="lastPage"
              />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

library.add(faEdit)

export default {
  name: 'AppProject',
  data() {
    return {
      isLoading: true,
      error: null,
      tableItems: [],
      projectPublicId: null,
      currentPage: 1,
      pageSize: 10,
      lastPage: 1,
      apiReady: -1,
      target: null,
      tableFields: [
        { key: 'id' },
        { key: 'project_public_id' },
        { key: 'project_title' },
        { key: 'target' },
        { key: 'status' },
        { key: 'action' }
      ],
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getProjectsData();
      },
    },
    deep: true,
  },
  methods: {
    getProjectsData () {
      this.isLoading = true
      this.error = null
      let queryParam = '?page=' + this.currentPage + '&limit=' + this.pageSize
      if (this.apiReady !== -1) {
        queryParam += '&apiReady=' + this.apiReady
      }
      if (this.target !== null) {
        queryParam += '&target=' + this.target
      }
      if (this.projectPublicId && this.projectPublicId !== null) {
        this.apiReady = -1
        this.target = null
        queryParam = '?projectId=' + this.projectPublicId
      }
      const apiEndpoint = process.env.VUE_APP_API_ENDPOINT + '/projects/app' + queryParam
      const header = {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt')
        }
      }
      const projectsData = axios.get(apiEndpoint, header)
        .then(({ data }) => {
          this.tableItems = data.data.projects
          this.currentPage = data.data.pagination.currentPage
          this.lastPage = data.data.pagination.lastPage
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.error = error
        })
    },
    formatAmount (amount) {
      if (amount && amount !== undefined) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    getBadge (status) {
      return status === true ? 'success' : 'danger'
    },
    getStatusSymbol (status) {
      return status === true ? '&#10003;' : '&#10007;'
    },
    handleApiReady (value) {
      this.apiReady = value;
    }
  },
  mounted() {
    this.getProjectsData();
  },
}
</script>
